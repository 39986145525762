import React from 'react';

const Calendar = () => {
  return (
    <div>
      <div>
        <h1>Calendar</h1>
        <iframe
          width="800"
          height="600"
          scrolling="no"
          data-src="https://calendar.google.com/calendar/embed?height=600&amp;wkst=1&amp;bgcolor=%23ffffff&amp;ctz=America%2FToronto&amp;src=YWRtaW5Abm9ic3JmYy5jb20&amp;color=%23795548&amp;showNav=1&amp;showTabs=1&amp;showCalendars=1"
          src="data:image/gif;base64,R0lGODlhAQABAAAAACH5BAEKAAEALAAAAAABAAEAAAICTAEAOw=="
          data-origwidth="800"
          data-origheight="600"
        ></iframe>
      </div>
    </div>
  );
};

export default Calendar;
